import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
// @ts-ignore
import Layout from "../components/layout"
// @ts-ignore
import Seo from "../components/seo"
import "./index.scss";
import { APP_DOWNLOAD_URL } from "../commons/constant/constants";
import { BuryingPointFc } from "../components/buryingPoint";
const isPhone = () => {
  if (typeof window !== `undefined`) {
    //判断是不是移动端
    const result = (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i));
    if (result) {
      // 移动端
      return true;
    }
    else {
      //PC端页面
      return false
    }
  }
}

const IndexPage = () => (
  <Layout>
    <Seo title="里德笔记官网" />
    {/* <!-- Home, First Screen --> */}
    <section style={{   overflow:'hidden',position:'relative' }} id="home" className="bg-dark text-light pt-5 p-lg-0 pt-lg-5 text-sm-start homeBack">
        
        {/*  视频版本 */}
        <video className="myvideo" playsInline autoPlay muted loop style={{width: '100%', position: 'absolute', top:'0px',left: '0',right: '0', margin: 'auto' }} >
          <source src="https://wepro.oss-cn-shenzhen.aliyuncs.com/web/home.mp4" type="video/mp4" />
        </video>
      <div id="homeCarousel" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner my-carousel-box" >
          <div className="carousel-item active home-carouse px-5">
            <div className="container pb-5 bannerBox">
              <div className="d-sm-flex align-items-center justify-content-between imgMsg">
                <div>
                  <h1>
                  低成本
                    <span className="text-primary userRoleTitle">
                    获客
                    </span>
                    
                  </h1>
                  <p className="lead my-4 describe">
                    {/* 商家中心具备项目管理、活动管理、任务创建、任务订单审核、支付管理、提现管理等内容营销全流程的能力。 */}
                    {/* 基于新媒体内容引流! &nbsp;&nbsp;发动员工参与、外部自媒体创作者参与。一键派单（媒体代发）、创作者抢单（智能匹配），基于标准化流程快速起盘，快速见效。 */}
                    将短视频、笔记、软文 做到极致! 引流拓客，助力业绩增长!
                  </p>
                 
                  <a onClick={()=>BuryingPointFc('VO','VO00300100100803',{action:'点击品牌增长轮播图-开启试用按钮'})} className="btn btn-lg " href="http://j.lidebiji.com/user/register" target="_blank" role="button" style={{ color: '#fff', background: '#2878FC' }}>
                    开启试用
                  </a>
                </div>
                <StaticImage id="mc-workbench" className="d-none d-sm-block" style={{ maxWidth: '850px' }}
                  src="https://wepro.oss-cn-shenzhen.aliyuncs.com/web/shangjiazhongxin.png" alt="里德笔记" />
                  {/* src="../../static/home/shangjiazhongxin.png" alt="" /> */}
              </div>
            </div>
          </div>
          <div className="carousel-item home-carouse px-5" >
            <div className="container pb-5 bannerBox">
              <div className="d-sm-flex align-items-center justify-content-between imgMsg">
                <div>
                  <h1>
                    内容
                    <span className="text-primary userRoleTitle">
                      变现
                    </span>
                  </h1>
                  <p className="lead my-4">
                    一单一结，自助提现! 热爱生活，自由工作
                  </p>
                  {
                    !isPhone() ? 
                    <a onClick={()=>BuryingPointFc('VO','VO00300100100804',{action:'点击内容变现轮播图-创作者入住按钮'})} className="btn btn-lg" style={{ color: '#fff', background: '#2878FC' }} href="http://c.lidebiji.com/" target="_blank" role="button">
                      创作者入驻
                    </a> : <a className="btn btn-lg" style={{ color: '#fff', background: '#2878FC' }} onClick={()=>{
                      window.location.href = APP_DOWNLOAD_URL
                    }} role="button">
                      下载App
                    </a>
                  }
                </div>
                {/* className="img-fluid w-50 d-none d-sm-block" */}
                <StaticImage id="creator-phone" className="w-50 d-none d-sm-block" style={{ maxWidth: '300px', marginRight:'150px' }}
                  src="../../static/pages/home/app.png" alt="里德笔记" />
                  {/* src="../../static/pages/home/app.png"  alt="" /> */}
              </div>
            </div>
          </div>
        </div>
        
        <button onClick={()=> BuryingPointFc('VO','VO00300100100801',{action:'选择轮播图左翻按钮'})} className="carousel-control-prev" style={{ maxWidth: '8%' }} type="button" data-bs-target="#homeCarousel"
          data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">
            {/* Previous */}
            上一个
          </span>
        </button>
        
        <button onClick={()=> BuryingPointFc('VO','VO00300100100802',{action:'选择轮播图右翻按钮'})} className="carousel-control-next" style={{ maxWidth: '8%' }} type="button" data-bs-target="#homeCarousel"
          data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">
            {/* Next */}
            下一个
          </span>
        </button>
      </div>

    </section>
    {/* <!-- Q&A Accordion --> */}
    <section id="questions" style={{ padding: '3rem 0' }}>
      <div className="container">
        <h2 className="text-center mb-4">
          {/* Frequently Asked Questions */}
          常见问答
        </h2>
        <div className="accordion accordion-flush" id="questions">
          <div className="accordion-item">
            <h2 className="accordion-header">
            
              <button onClick={()=>BuryingPointFc('VO','VO00300100100901',{action:'作为品牌商家，如何评估效果?'})} className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#question-one">
                {/* Does ContentWork require a fee? */}
                Q1、作为品牌商家，如何评估效果?
              </button>
            </h2>
            <div id="question-one" className="accordion-collapse collapse" data-bs-parent="#questions">
              <div className="accordion-body">
                您可以通过关键词排名、目标人群触达数量、超越竞争对手的品牌影响力、流量/线索/订单增长量 等来评估在里德笔记的运营效果
              </div>
            </div>
          </div>
          {/* <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#question-two">
                个人或个体工商户是否可以成为商家？
              </button>
            </h2>
            <div id="question-two" className="accordion-collapse collapse" data-bs-parent="#questions">
              <div className="accordion-body">
                里德笔记当前仅接受有资质的企业、公司成为商家，暂不接受个人身份。若您为企业组织或者公司、社会团体，可以通过“里德笔记商家中心”网页（ <a href="http://j.lidebiji.com/" target={'_blank'}>http://j.lidebiji.com/</a> ）进行注册，并申请试用即可。
              </div>
            </div>
          </div> */}
          <div className="accordion-item">
            <h2 className="accordion-header">
            
              <button onClick={()=>BuryingPointFc('VO','VO00300100100902',{action:'如何快速测试推广效果?'})} className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#question-two">
                Q2、如何快速测试推广效果?
              </button>
            </h2>
            <div id="question-two" className="accordion-collapse collapse" data-bs-parent="#questions">
              <div className="accordion-body">

                您通常可以在3分钟内完成一次推广测试。平台拥有6.5w+媒体资源(其中自媒体4.5w+,新闻媒体2w+)。<br></br>具体步骤：
                "注册" - "一键代发" - "选择账号" - "选择适合的媒体" 即可。<br></br>
                建议您可先尝试网易、百度、小红书、抖音平台，见效更快。
                可先尝试选择40-50元单价区间的媒体，且与您的目标人群/行业相匹配。

              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
            
              <button onClick={()=>BuryingPointFc('VO','VO00300100100903',{action:'相对于软文或短视频一键代发，还有更低成本的获客功能吗?'})} className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#question-three">
                Q3、相对于软文或短视频一键代发，还有更低成本的获客功能吗?
              </button>
            </h2>
            <div id="question-three" className="accordion-collapse collapse" data-bs-parent="#questions">
              <div className="accordion-body">
                {/* 首先您需要登录“里德笔记商家中心”网页（ <a href="http://j.lidebiji.com/" target={'_blank'}>http://j.lidebiji.com/</a> ）注册一个账号，然后按照“商家使用指南”填写基本信息，就可以免费试用项目。 */}
                是的，"矩阵获客"，步骤如下<br></br><br></br>

                1. 创建项目<br></br>
                2. 上架任务<br></br>
                3. 审核任务<br></br>
                4. 查看发布效果
                <br></br>
                <br></br>如果您在试用过程中遇到困难，请联系客服微信: LideY2023
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
            
              <button onClick={()=>BuryingPointFc('VO','VO00300100100904',{action:'我们为您准备了一份更为详细的"里德笔记增长飞轮"专业文档! '})} className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#question-four">
                Q4、什么是"里德笔记增长飞轮"？
              </button>
            </h2>
            <div id="question-four" className="accordion-collapse collapse" data-bs-parent="#questions">
              <div className="accordion-body">
                <p>
                {/* 文件: <a style={{color:'red',cursor:'pointer',marginLeft:5}} onClick={()=>{window.location.href = "https://wepro.oss-cn-shenzhen.aliyuncs.com/web/%E9%87%8C%E5%BE%B7%E7%AC%94%E8%AE%B0%E5%B9%B3%E5%8F%B0%E4%BB%8B%E7%BB%8D-v1.5.5.pdf"}}>里德笔记增长飞轮.pdf</a> */}
                {/* <br></br> */}
                {/* <br></br> */}
                如有需要，请联系客服微信免费获取: LideY2023
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
            
              <button onClick={()=>BuryingPointFc('VO','VO00300100100904',{action:'里德笔记通过哪些方面将 短视频、笔记、软文 做到极致?'})} className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#question-five">
                Q5、里德笔记通过哪些方面将 短视频、笔记、软文 做到极致?
              </button>
            </h2>
            <div id="question-five" className="accordion-collapse collapse" data-bs-parent="#questions">
              <div className="accordion-body">
                <p>
                首先，平台不断招募优秀的兼职创作者和自媒体参与内容创作;
                <br></br>
                其次，平台持续不断拓展新闻媒体机构入驻;
                <br></br>
                最后，商家还可以发动员工参与、外部合作伙伴或博主参与。
                <br></br>
                基于标准化流程运营，让您基于约 30% 的人力资源成本(相对于全职员工)，获得更大的营销效果。
                </p>
              </div>
            </div>
          </div>

          {/* <div className="accordion-item">
            <h2 className="accordion-header">
            
              <button onClick={()=>BuryingPointFc('VO','VO00300100100904',{action:'点击常见问答的“作为创作者，我如何获得订单”'})} className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#question-six">
                Q6、作为创作者，我如何获得订单？
              </button>
            </h2>
            <div id="question-six" className="accordion-collapse collapse" data-bs-parent="#questions">
              <div className="accordion-body">
                <p>
                  {'请先在手机应用市场下载里“德笔记APP”，按照提示注册账户。通过 “里德笔记App” > “频道” ，加入您感兴趣的频道，或者通过右上角的“扫一扫”，通过扫码加入频道。商家管理员审核通过后，您即可在"发现"栏(或者"频道">“任务”)领取相关任务。完成并提交任务 > 等待审核通过，即可自助提现。'}
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button onClick={()=>BuryingPointFc('VO','VO00300100100905',{action:'点击常见问答的“创作者中心为什么没有订单”'})} className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#question-seven">
                Q7、领取任务时，为何提示我需要入驻平台?
              </button>
            </h2>
            <div id="question-seven" className="accordion-collapse collapse" data-bs-parent="#questions">
              <div className="accordion-body">
              1. 平台公信力和公平的一份保障，避免乱接乱发等情况的发生<br></br>
              2. 权益保障，例如当你遇到恶意商家后可申诉等权利<br></br>
              3. 专人辅导，在你入驻之后将会有专人辅导，带你快速上手<br></br>
              <br></br>
              创作者缴纳入驻手续费并正式入驻后，方可接收派单或抢单。
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button onClick={()=>BuryingPointFc('VO','VO00300100100905',{action:'点击常见问答的“创作者中心为什么没有订单”'})} className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#question-eight">
                Q8、作为创作者，我如何提现?
              </button>
            </h2>
            <div id="question-eight" className="accordion-collapse collapse" data-bs-parent="#questions">
              <div className="accordion-body">
              自助提现
              <br></br>
              Web端操作步骤：登录 - 首页 - 点击"前往提现" - 填写信息 即可
              <br></br>
              APP端操作步骤：登录 - 我的 - 点击"提现" - 填写信息 即可
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button onClick={()=>BuryingPointFc('VO','VO00300100100905',{action:'点击常见问答的“创作者中心为什么没有订单”'})} className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#question-nine">
                Q9、作为创作者，我的收益从哪里来？
              </button>
            </h2>
            <div id="question-nine" className="accordion-collapse collapse" data-bs-parent="#questions">
              <div className="accordion-body">
              派单: 当你入驻成功后，后续在入驻里德笔记平台的自媒体博主后，会展示在平台商家会选择进行派单<br></br>
              抢单: 当你加入频道后，你可以领取你擅长的任务并完成获取收益<br></br>
              裂变: 当前每邀请一位创作者成功入驻，可以获得15.00元返利。<br></br>

              品牌方...

              频道。。。
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button onClick={()=>BuryingPointFc('VO','VO00300100100905',{action:'点击常见问答的“创作者中心为什么没有订单”'})} className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#question-ten">
                Q10、邀请创作者入驻平台，如何计算收益?
              </button>
            </h2>
            <div id="question-ten" className="accordion-collapse collapse" data-bs-parent="#questions">
              <div className="accordion-body">
              当前每邀请一位创作者成功入驻，可以获得15.00元返利。
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>

    {/* <!-- Modal --> */}
    <div className="modal fade" id="enroll" tabIndex={-1} aria-labelledby="enrollLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="enrollLabel">
              {/* Enrollment */}
              注册
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <p className="lead">
              {/* Fill out this form and we will get back to you */}
              填写此表格，我们会尽快回复您
            </p>
            <form>
              <div className="mb-3">
                <label htmlFor="first-name" className="col-form-label">
                  {/* First Name: */}
                  名：
                </label>
                <input type="text" className="form-control" id="first-name" />
              </div>
              <div className="mb-3">
                <label htmlFor="last-name" className="col-form-label">
                  {/* Last Name: */}
                  姓：
                </label>
                <input type="text" className="form-control" id="last-name" />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="col-form-label">
                  {/* Email: */}
                  电子邮件：
                </label>
                <input type="email" className="form-control" id="email" />
              </div>
              <div className="mb-3">
                <label htmlFor="phone" className="col-form-label">
                  {/* Phone: */}
                  电话号码：
                </label>
                <input type="tel" className="form-control" id="phone" />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
              {/* Close */}
              关闭
            </button>
            <button type="button" className="btn btn-primary">
              {/* Submit */}
              提交
            </button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
